import React from "react"
import { graphql } from "gatsby"
import Moment from "react-moment"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import Breadcrumb from "../components/elements/breadcrumb"

// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"

const AsmetPhotoTemplate = ({ data }) => {
  const photo = data.photoSingle

  const asmetHome = useAsmetHome()

  const crumbs = [
    { path: `/media`, label: "Media" },
    { path: `/fotos`, label: "Fotos" },
    { path: `/fotos/${photo.slug}`, label: photo.titulo },
  ]

  return (
    <Layout
      meta={
        photo.meta
          ? { ...photo.meta, og_tipo: "photo" }
          : {
              og_tipo: "photo",
              og_titulo: photo.titulo,
              og_descripcion: photo.descripcion || null,
            }
      }
    >
      <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbs={crumbs} />
              <h3 className="text-white-alpha text-left pt-4">{photo.titulo}</h3>
            </div>
          </div>
        </div>
      </div>
      <div id="asm-page-general" className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8">
            <p className="text-gray-beta text-right">
              <Moment format="LL">{photo.fecha}</Moment>
            </p>
            {photo.descripcion ? <p>{photo.descripcion}</p> : null}
          </div>
        </div>
      </div>
      <div className="asm-media-main container my-5">
        <div className="asm-media-grid card-columns">
          {photo.imagenes.map((item, index) => {
            return (
              <div className="card" key={index}>
                <img
                  alt={photo.titulo}
                  src={item.url}
                  className="card-img-top img-thumbnail"
                />
              </div>
            )
          })}
        </div>
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario} />
    </Layout>
  )
}

export default AsmetPhotoTemplate

export const query = graphql`
  query PhotoSingle($id: String!) {
    photoSingle: strapiMediaFoto(strapiId: { eq: $id }) {
      strapiId
      slug
      titulo
      meta {
        og_titulo
        og_imagen {
          publicURL
        }
        og_descripcion
      }
      fecha
      descripcion
      imagenes {
        formats {
          thumbnail {
            publicURL
          }
          small {
            publicURL
          }
          medium {
            publicURL
          }
          large {
            publicURL
          }
        }
        url
      }
    }
  }
`
